import {
  flip,
  offset as offsetMiddleware,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { MutableRefObject } from "react";

interface Props {
  shouldFlip?: boolean;
  containerPadding?: number;
  offset?: number;
  crossOffset?: number;
}
export function useOverlayPosition({
  shouldFlip = true,
  offset = 5,
  containerPadding = 16,
}: Props) {
  const { x, y, reference, floating, strategy, refs } = useFloating({
    placement: "bottom",
    middleware: [
      offsetMiddleware(offset),
      flip({
        padding: containerPadding,
        crossAxis: shouldFlip,
        mainAxis: shouldFlip,
      }),
      shift({ padding: containerPadding }),
    ],
  });
  return {
    triggerRef: reference,
    triggerObjRef: refs.reference as MutableRefObject<HTMLElement | null>,
    overlayRef: floating,
    overlayObjRef: refs.floating,
    objRefs: refs,
    positionStyle: {
      position: strategy,
      top: y ?? "",
      left: x ?? "",
    },
  };
}
