export enum Key {
  ENTER = 'Enter',
  SPACE = 'Space',
  ESCAPE = 'Escape',
  DELETE = 'Delete',
  ARROW_UP = 'ArrowUp',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
  HOME = 'Home',
  END = 'End',
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  S = 's',
  N = 'n',
  A = 'a',
  T = 't',
  B = 'b',
  C = 'c',
  O = 'o',
  P = 'p',
  R = 'r',
  F = 'f',
  Y = 'y',
  Z = 'z',
}
