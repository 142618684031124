import React, {forwardRef} from 'react';
import {AriaListBoxOptions, useListBox} from '@react-aria/listbox';
import {ListState} from '@react-stately/list';
import {useObjectRef} from '@react-aria/utils';
import {ListboxOption} from './listbox-option';
import {listboxWrapperStyle} from './listbox-style';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  state: ListState<unknown>;
  className?: string;
  style?: React.CSSProperties;
}
export const ListBox = forwardRef<HTMLUListElement, ListBoxProps>(
  ({className, style, ...props}, ref) => {
    const refObj = useObjectRef(ref);
    const {state} = props;
    const {listBoxProps} = useListBox(props, state, refObj);
    const mergedClassname = listboxWrapperStyle(className);
    return (
      <ul
        className={mergedClassname}
        style={style}
        {...listBoxProps}
        ref={refObj}
      >
        {[...state.collection].map(item => (
          <ListboxOption key={item.key} item={item} state={state} />
        ))}
      </ul>
    );
  }
);
