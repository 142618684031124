import React, {ReactElement} from 'react';
import {MenuTriggerProps} from '@react-types/menu';
import {FocusScope} from '@react-aria/focus';
import {AnimatePresence} from 'framer-motion';
import {useMenuTriggerState} from '@react-stately/menu';
import {useMenuTrigger} from '@react-aria/menu';
import {DismissButton} from '@react-aria/overlays';
import {useIsMobileDevice} from '../../../utils/hooks/is-mobile-device';
import {Popover} from '../../overlays/popover/popover';
import {MenuContext, MenuContextValue} from './menu-context';
import {Tray} from '../../overlays/tray';
import {useOverlayPosition} from '../../overlays/use-overlay-position';

interface Props extends MenuTriggerProps {
  children: ReactElement[];
}
export function MenuTrigger(props: Props) {
  const {children, closeOnSelect, shouldFlip = true} = props;

  const [menuTrigger, menu] = React.Children.toArray(children);
  const state = useMenuTriggerState(props);

  const {triggerRef, overlayRef, positionStyle, triggerObjRef} =
    useOverlayPosition({shouldFlip});

  const {menuTriggerProps, menuProps} = useMenuTrigger(
    {},
    state,
    triggerObjRef
  );

  const isMobile = useIsMobileDevice();

  const menuContext: MenuContextValue = {
    ...menuProps,
    onClose: state.close,
    closeOnSelect,
    autoFocus: state.focusStrategy || true,
  };

  const contents = (
    <FocusScope restoreFocus contain={isMobile}>
      <DismissButton onDismiss={state.close} />
      {menu}
      <DismissButton onDismiss={state.close} />
    </FocusScope>
  );

  // On small screen devices, the menu is rendered in a tray, otherwise a popover.
  let overlay: ReactElement;
  if (isMobile) {
    overlay = (
      <Tray isOpen={state.isOpen} onClose={state.close}>
        {contents}
      </Tray>
    );
  } else {
    overlay = (
      <Popover
        isOpen={state.isOpen}
        style={positionStyle}
        ref={overlayRef}
        hideArrow
        onClose={state.close}
        shouldCloseOnBlur
      >
        {contents}
      </Popover>
    );
  }

  return (
    <>
      {menuTrigger &&
        React.cloneElement(menuTrigger as ReactElement, {
          ...menuTriggerProps,
          ref: triggerRef,
          isPressed: state.isOpen,
        })}
      <AnimatePresence>
        {state.isOpen && (
          <MenuContext.Provider value={menuContext}>
            {overlay}
          </MenuContext.Provider>
        )}
      </AnimatePresence>
    </>
  );
}
