import React, { ReactElement, ReactNode, RefObject } from "react";
import { Placement } from "@react-types/overlays";
import { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTrigger } from "@react-aria/overlays";
import { Popover } from "./popover";
import { DialogTriggerBase } from "../dialog-trigger/dialog-trigger-base";
import { useOverlayPosition } from "../use-overlay-position";

export interface PopoverTriggerProps {
  state: OverlayTriggerState;
  trigger?: ReactElement;
  targetRef?: RefObject<HTMLElement>;
  children: ReactNode;
  hideArrow: boolean | undefined;
  placement?: Placement;
  containerPadding?: number;
  offset?: number;
  crossOffset?: number;
  shouldFlip?: boolean;
}
export function PopoverTrigger({
  state,
  targetRef,
  trigger,
  hideArrow,
  children,
  ...props
}: PopoverTriggerProps) {
  const { triggerRef, overlayRef, positionStyle, triggerObjRef } =
    useOverlayPosition(props);

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    triggerObjRef
  );

  const overlay = (
    <Popover
      style={positionStyle}
      isOpen={state.isOpen}
      ref={overlayRef}
      onClose={state.close}
      hideArrow={hideArrow}
    >
      {children}
    </Popover>
  );

  return (
    <DialogTriggerBase
      type="popover"
      state={state}
      triggerProps={{ ...triggerProps, ref: triggerRef }}
      overlayProps={overlayProps}
      overlay={overlay}
      trigger={trigger}
    />
  );
}
